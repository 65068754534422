.c-slides {
  position: relative;
  width: 100%;
  @include bp-lg {
    height: 100%;
  }
  &__item {
    width: 100%;
    height: 240px;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color-blue, 0.6);
      z-index: 1;
    }
    @include bp-sm {
      height: 400px;
    }
    @include bp-md {
      height: 480px;
    }
    @include bp-lg {
      height: 100%;
    }

  }
  &__container {
    @include grid-container;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__content {
    width: 100%;
    z-index: 2;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

  }

  &__title {
    margin-top: 0;
    font-weight: bold;
    color: $color-white;
    font-size: rem(26);
    @include bp-md {
      font-size: rem(36);
    }
    @include bp-lg {
      font-size: rem(40);
    }
    @include bp-xl {
      font-size: rem(50);
    }
  }



  &__btn {
    margin-top: rem(30);
  }

  .flickity-page-dots  {
    position: absolute;
    bottom: rem(20);
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }
  .dot {
    display: inline-block;
    width: rem(10);
    height: rem(10);
    margin-left: rem(5);
    margin-right: rem(5);
    background-color: $color-white;
    border-radius: 100%;
    vertical-align: middle;
    cursor: pointer;
    opacity: 1;
  }
  .dot.is-selected {
    width: rem(14);
    height: rem(14);
    border: 2px solid $color-white;
    background-color: transparent;
  }
}
