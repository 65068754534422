/* Breakpoints
   ========================================================================== */


 // set breakpoints labels visible to jquery for css <-> js communication
 // the breakpoint name is added to the body:before content
 // when it comes to breakpoints

 body {
   &:before {
    visibility: hidden;
    position: absolute;

    @include respond-up-to(xs) {
      content: "xs";
    }
    @include bp-sm {
      content: "sm";
    }
    @include bp-md {
      content: "md";
    }
    @include bp-lg {
      content: "lg";
    }
    @include bp-xl  {
      content: "xl";
    }
  }
}
