%l-container {
  padding-left: 2%;
  padding-right: 2%;
  @include bp-md {
    @include flex();
    margin-left: auto;
    margin-right: auto;
  }
}
.l-container {
  @extend %l-container;
}
