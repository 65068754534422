/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-top: none;
  border-bottom: 1px solid $color-gray-lighter;
}
