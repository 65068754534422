.o-grid {
  @include grid-row;
  &__item {
    @include grid-column(12);
    margin-bottom: rem($global-spacing/8);
    flex-basis: 100%;
    @include bp-md {
      @include grid-column(6);
    }
    @include bp-lg {
      @include grid-column(3);
    }
  }
  @include bp-lg {
    &__image {
      &:hover {
        opacity: 0.85;
      }
    }
  }

}
