.c-services {
  position: relative;
  padding-top: rem(60);
  padding-bottom: rem(60);
  color: $color-white;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-orange, 0.8);
    z-index: 1;
  }

  @include bp-lg {
    padding-top: rem(240);
    padding-bottom: rem(240);
  }

  &__container {
    @extend %c-section__container;
  }
  &__row {
    @extend %c-section__row;
    position: relative;
    z-index: 2;
  }

  &__title,
  &__subtitle,
  &__description {
    text-align: center;
    @include bp-lg {
      text-align: left;
    }
  }

  &__title {
    @extend %c-section__title;
    margin-top: 0;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__content {
    @include grid-column(12);
  }
  &__description {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(6);
      @include grid-offset(1);
    }
  }
  &__list {
    @include grid-column(12);
    padding-top: rem($global-spacing/2);
    @include bp-lg {
      @include grid-column(4);
    }
  }

  ul {
    @include list-reset;
    @include clearfix;
    li {
      margin-left: 3em;
      margin-bottom: 0.5em;
      &:before {
        @extend .fas;
        content: '\f00c';
        float: left;
        margin-left: -3em;
        font-size: 12px;

        @include bp-lg {
          font-size: initial;
        }
      }
    }
  }
}
