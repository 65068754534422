.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

// display tool in breakpoints

// define which position settings we need for each breakpoint
$positions: block inline-block flex;

// a position tool for each breakpoint
@each $position in $positions {
  .d-#{$position} {
    @each $breakpoint-abbr, $breakpoint-value in $breakpoints {
      @media screen and (min-width: $breakpoint-value) {
        &\@#{$breakpoint-abbr} {
          display: #{$position} !important;
        }
      }
    }
  }
}
