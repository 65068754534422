.c-button-up {
  position: relative;
  width: rem(45);
  padding: 0;
  font-size: rem(18);
  color: $color-white;
  background-color: $color-orange;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: $color-gray-light;
  }
  &:before {
    @extend .fas;
    content: "\f0d8";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include bp-md {
      position: initial;
      left: auto;
      top: auto;
      transform: none;
    }
  }
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    @include bp-md {
      content: none;
    }
  }

}
