body {
  //@extend .type--normal;
  font-weight: 300;
  color: $color-gray;
  font-size: 0.875em; /* 14px */

  @include bp-lg {
    font-size: 0.9375em; /* 15px */
  }

  @include bp-xl {
    font-size: 1em; /* 16px */
  }
}
