/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  @include bp-lg {
    transition: color 200ms $global-transition-easing;
    &:hover {
      color: $color-hover;
    }
  }
}
