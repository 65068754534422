.c-contact {
  @extend %c-section;
  position: relative;
  text-align: center;
  &__background {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    width: 40%;
    opacity: 0.15;
    z-index: -1;
    @include bp-lg {
      left: 0;
      width: cp(533, 1570);
      transform: none;
    }
  }
  &__container {
    @extend %c-section__container;
    position: relative;
  }
  &__row {
    @extend %c-section__row;
  }
  &__title {
    @extend %c-section__title;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__content {
    @include grid-column(12);
  }
  &__copy {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(6);
      @include grid-offset(3);
    }
  }

  &__form {
    @include grid-column(12);
    margin-top: rem($global-spacing/3);
    @include bp-lg {
      @include grid-column(8);
      @include grid-offset(2);
    }
  }

  &__logo {
    margin-top: rem(60);
    margin-bottom: rem(60);
    @include bp-lg {
      margin-top: rem(120);
    }
  }

  &__info {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(10);
      @include grid-offset(1);
    }
    @include bp-xl {
      @include grid-column(6);
      @include grid-offset(3);
    }
  }

  &__company {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(6);
      text-align: left;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__contacts {
    @include grid-column(12);
    margin-top: rem(60);
    @include bp-lg {
      @include grid-column(6);
      margin-top: 0;
    }
  }
}
