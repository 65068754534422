%c-section {
  padding-top: rem($global-spacing/6);
  padding-bottom: rem($global-spacing/6);
  @include bp-lg {
    padding-top: rem($global-spacing/2);
    padding-bottom: rem($global-spacing/2);
  }
  &__container {
    @include grid-container;
  }
  &__row {
    @include grid-row;
  }
  &__title {
    @extend .type--large;
    margin-bottom: rem(30);
    font-weight: 700;
    line-height: 1;
  }
  &__subtitle {
    @extend .type--xxsmall;
    margin-bottom: rem($global-spacing/5);
    font-weight: 600;
    text-transform: uppercase;
  }
  &__content {
    p {
      margin-bottom: 0;
    }
  }
}
