.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

// position tool in breakpoints

// define which position settings we need for each breakpoint
$positions: absolute relative;

// a position tool for each breakpoint
@each $position in $positions {
  .p-#{$position} {
    @each $breakpoint-abbr, $breakpoint-value in $breakpoints {
      @media screen and (min-width: $breakpoint-value) {
        &\@#{$breakpoint-abbr} {
          position: #{$position} !important;
        }
      }
    }
  }
}
