/**
 * Module: Type
 *
 * @author: Konstantinos Kataras
 */

@import '../tools/breakpoints';

$font-families: ()!default;
$font-weights: ()!default;
$font-family: 'Kanit', sans-serif;

$font-weights: (
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700
);

.type {
  -ms-word-break: break-word;
  font-style: normal;
  font-stretch: normal;

  &--xxsmall {
    font-size: rem(14) !important;
  }
  &--xsmall {
    font-size: rem(16) !important;
  }
  &--small {
    font-size: rem(18) !important;
  }
  &--normal {
    font-size: rem(20) !important;
  }
  &--medium {
    font-size: rem(26) !important;
  }
  &--large {
    font-size: rem(36) !important;
    @include bp-lg {
      font-size: rem(50) !important;
    }
  }
  &--xlarge {
    font-size: rem(36) !important;
    @include bp-lg {
      font-size: rem(80) !important;
    }
  }
  // alignment
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  &--center {
    text-align: center;
  }
  &--justify {
    text-align: justify;
  }
  &--underlined {
    text-decoration: underline;
  }
  &--nodeco {
    text-decoration: none;
  }

  @each $color-name, $color in $colors {
    &--#{$color-name} {
      color: $color !important;
    }
  }

  @each $font-family-name, $font-family in $font-families {
    &--#{$font-family-name} {
      font-family: $font-family, sans-serif !important;
    }
  }

  @each $font-weight-name, $font-weight in $font-weights {
    &--#{$font-weight-name} {
      font-weight: $font-weight !important;
    }
  }

}
