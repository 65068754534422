input,
textarea,
select,
button {
  -webkit-appearance: none; /* reset default ios form styles */
  font-family: $font-family;
  font-weight: 300;
  background-color: $color-white;
  border-radius: $global-border-radius;
  border-width: 1px;
  border-style: solid;
  border-color: $color-gray-lighter;
  box-shadow: none;
  outline: none;
  &:focus {
    border-color: $color-gray-light;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
      font-size: 16px !important;
    }
}
