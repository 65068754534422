.c-zaufali {
  @extend %c-section;
  padding-bottom: 0 !important;
  text-align: center;
  &__container {
    @extend %c-section__container;
  }
  &__title {
    @extend %c-section__title;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__logos {
    @include grid-column(12);

    @include bp-sm {
      @include grid-column(8);
      @include grid-offset(2);
    }
  }
}
