/* ==========================================================================
   #Hamburgers
   ========================================================================== */

// some custom settings to override the default ones

$hamburger-padding-x                       : 20px;
$hamburger-padding-y                       : 15px;
$hamburger-layer-width                     : 40px;
$hamburger-layer-height                    : 4px;
$hamburger-layer-spacing                   : 6px;
$hamburger-layer-border-radius             : 0;
$hamburger-layer-color  : $color-gray;
$hamburger-types        : (spin);

@import 'hamburgers/_sass/hamburgers/hamburgers.scss';

.hamburger.is-active {
  .hamburger-inner {
    &,
    &::before,
    &::after {
      background-color: $color-white;
    }
  }
}
