.c-contact-list {
  @include list-reset;
  @include bp-lg {
    text-align: left;
  }
  &__link {
    &:before {
      @extend .fas;
      @extend .type--small;
      margin-right: 0.7em;
    }
    &--telephone {
      &:before {
        content: "\f095";
        transform: rotate(90deg);
      }
    }
    &--email {
      &:before {
        content: "\f0e0";
      }
    }
  }
}
