.l-flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  &__header {
  }
  &__main {
    flex-grow: 1;
  }
  &__footer {
  }
}
