.c-socials {
  @include list-reset();
  display: inline-block;
  text-align: right;

  &__icon {
    display: inline-block;
    &:last-child {
      .c-socials__link {
        padding-right: 0;
      }
    }
  }

  &__link {
    display: block;
    padding-left: rem(15);
    padding-right: rem(15);
    font-size: rem(18);
    color: $color-gray-light;
    &:before {
      @extend .fab;
    }
    &--facebook {
      @extend .fa-facebook-f;
    }
    &--linkedin {
      @extend .fa-linkedin-in;
    }
  }
}
