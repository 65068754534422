/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  @extend %c-section;
  text-align: center;

  &__container {
    @extend %c-section__container;
  }
  &__row {
    @extend %c-section__row;
  }
  &__title {
    @extend %c-section__title;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__content {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(8);
      @include grid-offset(2);
    }
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
