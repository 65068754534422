.c-logos {
  @include grid-row;
  @include flex;
  padding-top: rem($global-spacing/2);
  flex-wrap: wrap;

  &__item {
    @include grid-column(6);
    position: relative;
    margin-bottom: rem($global-spacing/2);
    @include bp-md {
      @include grid-column(4);
    }
    @include bp-lg {
      @include grid-column(3);
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    /*
    @include flex;
    @include flex-horizontal-center;
    @include flex-vertical-center;
    */
    & > * {
      max-width: 100%;
      flex-shrink: 0;
    }

    &:hover {
      .c-logos__icon--bw {
        opacity: 0;
        transition: opacity 0.5s ease-out;
      }
      .c-logos__icon--color {
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.5s ease-out;
    &--bw {
      opacity: 1;
    }
    &--color {
      opacity: 0;

    }
  }
}
