.c-portfolio {
  &:nth-child(2n) {
    position: relative;
    .c-portfolio__desc {
      color: $color-white;
      &:before {
        background-color: $color-white;
      }
    }
    &:after {
      content: "";
      display: block;
      height: rem(250);
      position: absolute;
      bottom: -(rem(25));
      width: 100%;
      background-color: $color-orange;
      z-index: -1;
    }
  }
  &__desc {
    @extend .c-about__subtitle;
    padding-left: rem(130);
    position: relative;
    text-align: left;
    &:before {
      content: "";
      display: block;
      width: 80px;
      height: 3px;
      position: absolute;
      top: 7px;
      left: 0;
      background-color: $color-orange;
    }
  }
}
