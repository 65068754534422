.c-languages {
  @include list-reset();
  display: inline-block;

  &__item {
    display: inline-block;
    color: $color-gray-light;
    &:first-child {
      .c-languages__link {
        padding-left: 0;
      }
    }
    &--isactive {
      color: $color-orange;
    }
  }

  &__code {
    @extend .type--bold;
    padding-left: rem(15);
    padding-right: rem(15);
    text-transform: uppercase;
    color: inherit;
  }

  &__link {
    color: inherit;
  }


}
