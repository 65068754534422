.o-imgs {
  @include bp-md {
    margin-left: -(rem(25));
  }
  @include bp-lg {
    margin-left: -(rem(50));
  }
  &__item {
    margin-bottom: rem(25);
    @include bp-md {
      max-height: 160px;
      width: auto;
      margin-left: rem(25);
    }
    @include bp-lg {
      max-height: 250px;
      margin-left: rem(50);
      margin-bottom: rem(50);
    }
  }
}
