.c-footer {
  @extend .type--xsmall;
  padding-top: rem(15/2);
  padding-bottom: rem(15/2);
  color: $color-white;
  background-color: $color-gray;
  @include bp-md {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__content {
    @include grid-container;
    @include flex();
    @include flex-vertical-center();
    @include bp-md {
      @include flex-vertical-stretch();
    }
  }
  &__nav {
    @include flex();
    @include flex-vertical();
    flex: 1 0 auto;
    @include bp-md {
      @include flex-horizontal();
      @include flex-vertical-stretch();
      height: rem(45);
    }
  }
  &__copyright {
    @include flex();
    @include flex-vertical-center();
    padding-top: rem(15/2);
    padding-bottom: rem(15/2);
    @include bp-md {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__year {
    margin-left: 0.5em;
  }
}
