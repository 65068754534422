.l-nav {
  @include flex;
  @include flex-vertical-stretch;
  &__container {
    @include grid-container;
    @include flex;
    @include flex-horizontal;
    @include flex-vertical-stretch;
    @include flex-horizontal-justify;
    @include bp-lg {
      position: relative;
    }

  }
  &__all {
    @include bp-lg {
      @include flex;
      @include flex-vertical-stretch;
      @include flex-horizontal-center;
      flex: 1;
    }
  }
  position: relative;
  height: rem(120);
}
