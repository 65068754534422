.c-cookies {
  display: none;
  &__container {
    @include grid-container;
  }
  &__row {
    @include grid-row;
  }
  &__content {
    @include grid-column(12);
  }
  &--on {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: rem($utility-spacing/8);
    padding-bottom: rem($utility-spacing/8);
    background-color: $color-gray-light;
    z-index: 9999;
  }
  &__copy {
    margin-bottom: 1em;
    font-size: rem(14);
    color: $color-white;
    @include bp-md {
      margin-bottom: 0;
      padding-right: 10%;
    }
  }
  &__button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $color-orange;
    border-radius: 10px;
    &:after {
      @extend .fas;
      content: "\f00d";
      padding: rem(12);
      font-size: rem(24);
      color: $color-white;
    }
  }
}
