.c-about {
  @extend %c-section;
  position: relative;
  text-align: center;

  &__background {
    position: absolute;
    top: -140px;
    left: 50%;
    transform: translate(-50%);
    width: 40%;
    opacity: 0.15;
    z-index: -1;
    @include bp-lg {
      width: cp(533, 1570);
    }
  }

  &__container {
    @extend %c-section__container;
    position: relative;
  }
  &__row {
    @extend %c-section__row;
  }
  &__title {
    @extend %c-section__title;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__content {
    @include grid-column(12);
  }
  &__copy {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(6);
      @include grid-offset(3);
    }
  }
}
