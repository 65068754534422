.c-footer-nav {
  @include list-reset();
  @include flex();
  @include flex-vertical();
  @include bp-md {
    @include flex-horizontal();
  }
  &__item {
    @include flex();
    @include flex-vertical-stretch();
  }
  &__link {
    @include flex();
    @include flex-vertical-center();
    padding-left: 1em;
    padding-top: rem(15/2);
    padding-bottom: rem(15/2);
    color: $color-white;
    @include bp-md {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
