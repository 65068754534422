.c-highlights {
  padding-bottom: rem($global-spacing/2);
  @include bp-lg {
    padding-top: rem($global-spacing/2);
    padding-bottom: rem($global-spacing);
  }
  &__container {
    @extend %c-section__container;
    @include bp-lg {
      max-width: rem(1130) !important;
    }
  }
  &__row {
    @extend %c-section__row;
    @include flex-horizontal-center;
  }
  &__item {
    margin-bottom: rem(45);
    &:last-child {
      margin-bottom: 0;
    }
    text-align: center;
    @include grid-column(12);
    @include bp-md {
      @include grid-column(6);
    }
    @include bp-lg {
      @include grid-column(4);
      margin-bottom: 0;
    }
  }
  &__title {
    @extend .type--medium;
    @extend .type--bold;
    margin-top: 0;
    margin-bottom: rem(30);
  }
  &__description {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__placeholder {
    @include flex();
    @include flex-horizontal-center;
    @include flex-vertical-bottom;
    margin-bottom: rem(45);
  }
}
