.c-logo {
  @include flex;
  @include flex-vertical-stretch;
  @include flex-vertical-center;
  @include bp-lg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    max-height: 60px;
    width: auto;
    @include bp-xl {
      max-height: none;
    }
  }
}
