.c-realizacje {
  @extend %c-section;
  position: relative;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;

  &__container {
    @extend %c-section__container;
    position: relative;
  }
  &__row {
    @extend %c-section__row;
  }
  &__title {
    @extend %c-section__title;
  }
  &__subtitle {
    @extend %c-section__subtitle;
  }
  &__content {
    @include grid-column(12);
  }
  &__title,
  &__subtitle,
  &__content {
    color: $color-white;
  }
  &__copy {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(6);
      @include grid-offset(3);
    }
  }
}
