@mixin hover() {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.3s;
    background-color: $color-orange;
    //transform-origin: bottom left;
  }
  &:hover {
    color: $color-orange;
    &:after {
      transform: scaleX(1);
    }
  }
}

.c-main-nav {
  @include list-reset();
  @include bp-lg {
    @include flex();
  }
  &--telephone {
    position: relative;
    @include bp-lg {
      position: absolute;
      right: 15px;
      height: 100%;
    }
    &:before {
      @extend .fas;
      @include bp-lg {
        content: "\f095";
        position: absolute;
        left: -32px;
        top: 47%;
        transform: rotate(90deg) translateY(-50%);
        transform-origin: center 0;
        line-height: 1;
        font-size: rem(18);
      }
    }
  }

  &__item {
    @include flex();

    &:first-child {
      .c-main-nav__link {
        padding-left: 0;
      }
    }
    &:last-child {
      .c-main-nav__link {
        padding-right: 0;
      }
    }
  }
  &__link {
    position: relative;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    font-size: rem(18);
    font-weight: 600;
    text-transform: uppercase;
    color: $color-white;
    @include bp-lg {
      @include hover();
      padding-top: rem(44);
      padding-bottom: 0;
      padding-left: rem(5);
      padding-right: rem(5);
      font-size: rem(15);
      color: $color-gray;
    }
    @include bp-xl {
      padding-left: rem(13);
      padding-right: rem(13);
      font-size: rem(18);
    }
  }

  &--left {
    @include bp-lg {
      @include flex-horizontal-right;
      width: 100%;
      padding-right: 50px;
    }
    @include bp-xl {
      padding-right: 100px;
    }
  }

  &--right {
    @include bp-lg {
      width: 100%;
      padding-left: 50px;
    }
    @include bp-xl {
      padding-left: 100px;
    }
  }

}
