@include respond-up-to(lg) {
  .c-sliding-nav {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    &--slidein {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s, visibility 0.3s;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      padding-top: rem($global-spacing/2);
      padding-left: 10%;
      padding-right: 5%;
      background-color: $color-blue;
      z-index: 15;
      overflow: hidden;
    }
  }
}
