/* ==========================================================================
   #Body overflow
   ========================================================================== */

/**
 * Prevents elements from overflowing horizontally, especialy in IE10 & IE11
 * https://developer.mozilla.org/en-US/docs/Web/CSS/-ms-overflow-style
 */

html {
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

body {
  overflow-x: hidden;
}
