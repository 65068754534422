.c-top {
  padding-top: rem(6);
  padding-bottom: rem(6);
  text-align: right;
  border-bottom: 1px solid $color-gray-lighter;

  &__container {
    @include grid-container;
  }
}
