.c-contact-form {

  &__row {
    @include grid-row;
    margin-bottom: rem(30);
  }

  &__column {
    @include grid-column(12);
    //margin-bottom: rem(30);
    @include bp-lg {
      @include grid-column(6);
      @include flex;
      @include flex-vertical;
      margin-bottom: 0;
    }

    &--right {
      @include flex;
      @include flex-horizontal;
      @include flex-horizontal-justify;
      @include flex-vertical-stretch;
    }
  }

  &__input {
    @extend .type--small;
    width: 100%;
    margin-bottom: rem(15);
    padding: rem(20);
    color: $color-gray-light;
    &:last-child {
      @include bp-lg {
        margin-bottom: 0;
      }
    }
  }

  textarea {
    width: 100%;
    margin-bottom: 0;
  }

  &__submit {
    @extend .c-btn;
    box-shadow: none;
    outline: none;
    border: none;
  }

  &__ftr {
    @include grid-column(12);
    @include bp-lg {
      text-align: right;
    }
  }
}
